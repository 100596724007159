<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 10"
  >
    <path
      fill="currentColor"
      d="M9 .4 5 4.3l-4-4a.5.5 0 0 0-.6.8L4.3 5l-4 4a.5.5 0 1 0 .8.6L5 5.7l4 4a.5.5 0 0 0 .6-.8L5.7 5l4-4a.5.5 0 1 0-.8-.6Z"
    />
  </svg>
</template>
